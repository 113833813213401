<template>
  <referentiel-list
    :listTitle="'Liste des organismes'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.nom"
                @input="handleInput"
                type="text"
                class="form-control"
                id="nom"
                name="nom"
                placeholder="Nom"
              />
              <label for="nom">Nom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
            <div class="form- mb-3">
              <label for="nom">Roles</label>

              <multiselect
                v-model="editableItem.roles"
                :options="roles"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Roles"
                label="name"
                track-by="id"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import { mapGetters } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";

export default {
  components: {
    ReferentielList,
    Multiselect,
  },
  computed: {
    ...mapGetters({
      organismes: "organisme/organismes",
      roles: "role/roles",
    }),
    referentielName() {
      return "fonction";
    },
    emptyReferentielItem() {
      return {
        intitule: null,
        description: null,
        parent: null,
        roles: [],
      };
    },
    tableFields() {
      return ["#", "index", { key: "nom", label: "Nom" }, "actions"];
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    ...mapActions({
      fetchRoles: "role/fetchRoles",
    }),
  },
};
</script>

<style></style>
